<template>
  <div class="home">
    <h3>欢迎使用</h3>
    <h1>统一管理平台</h1>
    <p v-if="addRouters.length === 0">
      当前账号并未配置任何权限，请联系
      <strong>系统管理员</strong>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['addRouters']),
  },
};
</script>
<style scoped>
.home {
  text-align: center;
}
p {
  color: #f17a23;
}
</style>
